/**
 * Auth0 User Update
 * Update the user in the Auth0 Database
 */
import { navigate } from "gatsby"

async function updateUserRecord(id, data, triggerBuild = false, token) {
  const params = {
    id,
    data,
    triggerBuild,
  }

  const response = await fetch("/.netlify/functions/update-user", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })

  if (response.ok) {
    return await response.json()
  } else {
    if (response.status === 401) {
      console.log("session expired, logging out")
      // navigate("/logout")
    } else {
      const err = await response.json()
      return {
        error: {
          status: response.status,
          message: err.error,
        },
      }
    }
  }
}

export default updateUserRecord
