import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"

import Layout from "../../components/layout"
import Banner from "../../components/banner"
import Seo from "../../components/seo"
import updateUserRecord from "../../bssr-api/auth0/update-user"
import getUserRecord from "../../bssr-api/auth0/get-user"
import { isBrowser } from "../../utils/auth"

function ConfirmationPage() {
  const { getIdTokenClaims } = useAuth0()

  useEffect(() => {
    async function updateUser() {
      if (isBrowser) {
        const user_id = sessionStorage.getItem("user_id")
        const claims = await getIdTokenClaims()

        if (claims && claims.__raw) {
          const token = claims.__raw
          const user = await getUserRecord(user_id, token)

          if (user) {
            if (user.data.app_metadata.account_type === "free") {
              await updateUserRecord(
                user_id,
                {
                  app_metadata: {
                    account_type: "pending",
                    role: "lapsed",
                  },
                },
                false,
                token
              )
            }
          } else {
            navigate("/account")
          }
        } else {
          navigate("/account")
        }

        // Remove the user_id from the session
        sessionStorage.removeItem("user_id")
      }
    }

    updateUser()
  })

  return (
    <Layout>
      <Seo title="Complete Your Membership" />
      <Banner label="Join the BSSR">Confirmation</Banner>

      <div className="container lg:max-w-5xl mx-auto py-3 lg:py-12 xl:text-lg">
        <h2 className="text-2xl lg:text-4xl font-bold mb-4">
          Welcome to the BSSR
        </h2>
        <p>
          Congratulations your are now a member of the BSSR. We are currently
          processing your payment. Once your payment has been processed
          successfully, you will receive a confirmation email and you will be
          able to access exclusive content on the website.
        </p>

        <Link
          to="/account"
          className="bg-indigo-900 inline-block hover:bg-indigo-800 mt-2 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3"
        >
          Return to your account
        </Link>
      </div>
    </Layout>
  )
}

export default ConfirmationPage
